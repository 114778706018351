.menu-contable{
    height: 90vh;
}

@media (max-width: 660px) {
    .menu-contable {
            height: auto;
        }}



        .submodulossize{
            font-size: 1.4rem!important;
            font-weight: 500;
        }