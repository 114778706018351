.ruc{
    font-size: 18px!important;
    font-weight:900 ;
}

.codigo_documento {
    font-size: 18px !important;
    font-weight: 900;
}

