.numero{
    font-size: 25px !important;
    text-align: center;
}

.titulosorteo{
    font-size: 30px !important;
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: center;
}
.borde{
    border: 1px solid black!important;
}

.padding12{
        padding: 15px!important;
}