.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.touppercase{
  text-transform: uppercase;
}

@media (min-width: 992px){
.content {
    padding: 25px 0;
}
}

.table > :not(:first-child) {
  border-top: none!important;
}

.cursor:hover{
  cursor: pointer;
}


/*INPUT FILE*/
.absolute{
  position:absolute;
}

.opacoUpload{
  opacity: 0!important;
}

.opacoUpload:hover{
  opacity: 0.9!important;
}

.form-group {
  text-align: left!important;
  margin-bottom: 12px;
}

.titulos{

font-size: 18px!important;
font-family:inherit
}



/*AUTOCOMPLETADO*/

.sugerencia {
  padding: 5px;
}


.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 5px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 14px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  max-height: 300px;
  overflow: auto;
  margin-top: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.react-autosuggest__suggestions-list {
  list-style: none;
  margin-bottom: 0px;
  padding-left: 0px;
  cursor: pointer;
}

.react-autosuggest__input {
  width: 100%;
      padding: 0.375rem 0.75rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        appearance: none;
        border-radius: 0.25rem;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}





/* =========== */


