#page-numbers {
    list-style: none;
    display: flex;
  }
  
  #page-numbers > li {
    margin-right: 0.3em;
    color: blue;
    user-select: none;
    cursor: pointer;
  }

  