body {
    height: 100vh;
     
}


*{
font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
          font-size: 0.875rem;
                  line-height: 1.55;
}





svg{
  width: 16px!important;
}

.svgIv{
    width: 16px!important;
    margin-right:5px;

}
.faIv{
  font-size: 14px!important;
  margin-right: 5px;
}

.letra-blanca{
  color: white!important;
}


.buscarpacientelist{
      max-height: 350px;
        overflow-y: scroll;
}

.w150{
  min-width: 150px!important;
}

.row > *{
  margin-left: 0!important ;
  margin-right: 0!important;
}


.row {
  margin-left: 0!important ;
  margin-right: 0!important;
}

.fijopanelleft {
  margin-left: 25% !important;
}

.header-fixed .header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  height: 10vh;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

a{
  text-decoration: none!important;
  color: #100f0f!important;
  /* font-weight: 600; */
}

.acordionBotonColorText {
  color: rgb(10, 10, 10) !important;
}

 .borde-layout {
    box-shadow: -1px 3px 6px 3px rgb(202 212 228);
  } 

.borde-tarjeta {
  box-shadow: 0px 0px 3px 0px rgb(200, 193, 193);
    background-clip: border-box !important;
 /*  box-shadow: 0 0 7px 0px rgb(187, 188, 191); */
}



.borde-modal {
  box-shadow: 0 12px 28px 0 rgb(202 212 228), 0 2px 4px 0 rgb(202 212 228),
    inset 0 0 0 1px rgb(202 212 228);
}

.borde {
  --T68779821: 0 1px 2px var(--shadow-2);
  --webkit-box-shadow: var(--T68779821);
  box-shadow: 0 1px 2px var(--shadow-2);
}

/*  .borde {
    box-shadow: 3px 3px 9px -6px rgb(89 97 103);
  } */
  .zindex{
    z-index: 90!important;
  }

.card {
  border: none !important;
  border-radius: 10px !important;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #ebedf3;
  border-radius: 0rem;
}

.card-body {
  padding: 12px;
}

.card-header {
  padding: 12px;
  margin-bottom: 0;
  background-color: #f2eeee;
  border-bottom: 1px solid #ebedf3;
  color: #100f0f;
  box-shadow: -1px 3px 6px 1px rgb(202 212 228);
}

.bg-Black {
  background: rgb(42 46 51);
}

.bg-green {
  background: green !important;
  color: white !important;
  margin: 1px;
}

.bg-red {
  background: red !important;
  color: white !important;
  margin: 1px;
}

.bg-integralvet {
  /*background: #2196f3 !important;*/
  background-color: #007ee2;
  border-bottom: "1px solid #d8d8d8";
  color: white;
}
.bordecircular{
  border-radius: 12px;
}

.bordecircularpanel {
  border-radius: 20px;
/*   min-height: 50%;
 */}




.modal .modal-header>button {
  border: 1px solid black !important;
  padding: 0px !important;
  border-radius: 45px !important;
  background-color: white;
  color: black;
  width: 30px;
  height: 30px;
}
.modal-content{
  /* padding: 12px; */
  border-radius: 7px;
  box-shadow: 0px 3px 8px 1px rgb(169 164 164 / 68%);
  background-clip: border-box!important;
}

.modal .modal-header .close span {
  display: block !important;
  color: black;
  font-size: 18px!important;
}


.cabeceraNotify {
  padding: 0.5rem 1.75rem !important;
}

.notifygif {
  width: 100%;
  padding-right: 0.5px;
  padding-left: 0.5px;
}

/*LOGIN STYLES*/
.bg-size {
  background-size: 100%;
}

@media(min-width: 993px){
  .offcanvas.offcanvas-right.offcanvas-on {
    -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    right: 0;
    left: auto;
   margin-top: 0px!important;
}
}

.bg-btn-default{
  background-color: #e4e6eb !important;
}

.bg-btn-default-red {
  background-color: #c90f1f !important;
  color: white;
  border: 1px solid #c90f1f;
}

.bg-btn-default-green {
  background-color: #c90f1f !important;
  color: white;
  border: 1px solid #c90f1f;
}

.bg-btn-default-blue {
  background-color: #004aad !important;
  color: white;
  border: 1px solid #004aad;
}

@media (max-width: 992px) {
  .card {
    margin-top: 15px !important;
  }
  .bg-size {
    background-size: 55% !important;
  }
  .modal-dialog {
    max-width: 90% !important;
  }


}

@media (max-width: 662px) {
  
 
  .bg-size {
    background-size: 75% !important;
    margin-bottom: 10px;
  }
  .notifygif {
    width: 100%;
    padding-right: 90.5px;
    padding-left: 90.5px;
    margin-bottom: 12px;
  }
  .modal-dialog {
    max-width: 100% !important;
  }
  .fijo {
    position: inherit !important;
    height: 100% !important;
    z-index:0
  }



 /*  .header-fixed .header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 97;
    height: auto!important;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  } */
  
}

.tarjetalogin{
  width: 35%!important;
}
.movil {
  display: inline-block !important;
}
.movilshow {
  display: none !important;
}

@media (max-width: 991.98px){
.header-mobile-fixed .wrapper {
    padding-top: 0px
}
.movil{
  display: none!important;
}



.movilshow {
  display: block !important;
}

.tarjetalogin{
  width: 90%!important;
}


/* .header-mobile-fixed .header-mobile {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3000;
  height: 55px;
  min-height: 64px;
} */

  .bg-size {
    background-size: 75% !important;
    margin-bottom: 10px;
  }
  .notifygif {
    width: 100%;
    padding-right: 90.5px;
    padding-left: 90.5px;
    margin-bottom: 12px;
  }
  .modal-dialog {
    max-width: 100% !important;
  }
  .fijo {
    position: inherit !important;
    height: 100% !important;
    z-index:0!important
  }
    .fijopanelleft {
    margin-left: 0!important;
  }
 
}


/*Acordion estylos personalizados*/
.card-header-acordion {
  padding: 0rem 2.25rem;
  margin-bottom: 0;
  background-color: #e8f0fe;
  border-bottom: none;
  color: black !important;
  border-radius: none !important;
}

.card-header:first-child {
  border-radius: none !important;
}

.transicioncolor {
  background-color: #9b59b6;
  -webkit-transition: font-size 1s ease;
  transition: font-size 1s ease;
}

.negrita {
  font-weight: bold !important;
}

.card.card-custom > .card-body {
  padding: 0;
}

.btn {
  border-radius: none !important;
}

.btn-menu-personalizado {
  display: inline-block;
  font-weight: normal;
  color: #3f4254;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: none;
}

.btn {
  display: inline-block;
  font-weight: normal;
  color: #3f4254;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0rem;
  -webkit-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
    -webkit-box-shadow 0.3s ease-in-out;
}


/* .header-fixed .wrapper {
    padding-top: 51px !important;
  } */


.bordetr {
  box-shadow:0px 2px 2px 0px rgb(202 212 228);
  cursor:pointer
}
.bordetr:hover{
  background-color: #e3e3e3;
}


.cursor{
  cursor: pointer!important;
}
.cursorselect :hover{
    background-color:#fc4718 ;
    color:white
}
#btn-0:hover {
  background-color: #f5f4f4 !important;
}

#btn-1:hover {
  background-color: #f5f4f4 !important;
}
#btn-2:hover {
  background-color: #f5f4f4 !important;
}
#btn-3:hover {
  background-color: #f5f4f4 !important;
}

#btn-perfil1:hover {
  background-color: red !important;
}

.container {
  max-width: 100% !important;
}





  .fijo{
    position: fixed;
    height: 100%;
    background: none;
  }

.titulosSize {
  font-size: 15px!important;
  font-weight: bold;
  color: grey;
}
.menuitemsize {
  font-size: 17px !important;
  font-weight: bold;
  margin-left: 12px;
  text-decoration: none;
  color: black;
  float: left;
}

.menu_container .link {
  padding-left: 20px;
  /*   border-bottom: #fff 0.5px solid;
 */
}

.link {
  display: flex;
  color: black;
  gap: 10px;
  padding: 5px 7px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link:hover {
  border-right: 4px solid white;
  background: #fc4718;
  transition: 0.1s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link:hover>* {
  color: white;
  fill: white;
}

.menu_item {
  display: flex;
  gap: 10px;
}

.menu_container {
  display: flex;
  flex-direction: column;
}

.floatderecha {
  float: right;
}
.floatizquierda {
  float: left;
}



.initspan {
  background: none;
  color: black;
}
.menuspam {
  color: red;
}

/*=================*/
.custom-switch.custom-switch-sm .custom-control-label::after {
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  border-radius: calc(1rem - (1rem / 2));
}

.custom-switch.custom-switch-sm
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(calc(1rem - 0.25rem));
}

/* for md */

.custom-switch.custom-switch-md .custom-control-label {
  padding-left: 2rem;
  padding-bottom: 1.5rem;
}

.custom-switch.custom-switch-md .custom-control-label::before {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}

.custom-switch.custom-switch-md .custom-control-label::after {
  width: calc(1.5rem - 4px);
  height: calc(1.5rem - 4px);
  border-radius: calc(2rem - (1.5rem / 2));
}

.custom-switch.custom-switch-md
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(calc(1.5rem - 0.25rem));
}

/* for lg */

.custom-switch.custom-switch-lg .custom-control-label {
  padding-left: 3rem;
  padding-bottom: 2rem;
}

.custom-switch.custom-switch-lg .custom-control-label::before {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
}

.custom-switch.custom-switch-lg .custom-control-label::after {
  width: calc(2rem - 4px);
  height: calc(2rem - 4px);
  border-radius: calc(3rem - (2rem / 2));
}

.custom-switch.custom-switch-lg
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(calc(2rem - 0.25rem));
}

/* for xl */

.custom-switch.custom-switch-xl .custom-control-label {
  padding-left: 4rem;
  padding-bottom: 2.5rem;
}

.custom-switch.custom-switch-xl .custom-control-label::before {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}

.custom-switch.custom-switch-xl .custom-control-label::after {
  width: calc(2.5rem - 4px);
  height: calc(2.5rem - 4px);
  border-radius: calc(4rem - (2.5rem / 2));
}

.custom-switch.custom-switch-xl
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(calc(2.5rem - 0.25rem));
}

/*STYLOS IMAGENES*/
.containerImg {
  position: relative;
  width: 100%;
}

.imageImg {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middleImg {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  right: -41%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.containerImg:hover .imageImg {
  opacity: 0.3;
}

.containerImg:hover .middleImg {
  opacity: 1;
}

.textImg {
  background-color: #0446aa;
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}

.bgfondo {
/*   background-color: #f0f2f5;
 */
background-color: white;
}
.bgfondodark {

background-color: #f0f2f5}


.negrita {
  font-weight: bold;
}
.bordebottom {
  border-bottom: 1px solid #f0f2f5 !important;
}
.bordetop {
  border-top: 1px solid #f0f2f5;
}

.textTitleFondo {
  font-weight: 500;
}
.badgevet{
  margin-top: -13px;
  position: fixed;
  margin-left: -4px;
  padding: 6px;
  width: 23px;
  background: red;
  color: white;
  font-weight: bold;
  font-size: 14px;
}


.inputIcon{
  width: 15%;
      height: 45px;
    /*   box-shadow: 0px 0px 1px 1px #00000036; */
      z-index: 10;
        
        background: white;
}
.inputIconText{
  max-width: 20%;
  width:20%;
      height: 45px;
      box-shadow: 0px 0px 1px 1px #00000036;
      z-index: 10;
}

.inputtext {
  width: 100%;
    padding: 8px;
    text-decoration: none;
    background: white !important;
    height: 40px !important;
    overflow: auto;
    border: none;
    border-radius: 5px;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
}

.inputtextsmall {
  width: 100%;
  padding: 5px;
  text-decoration: none;
  background: white !important;
  height: 28px !important;
  overflow: auto;
  border: none;
  border-radius: 5px;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
}
.inputselectsmall {
  width: 100%;
  padding: 5px;
  text-decoration: none;
  background: white !important;
  height: 28px !important;
  overflow: auto;
  border: none;
  border-radius: 5px;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
}
.inputtext2 {
  width: 100%;
  padding: 8px;
  text-decoration: none;
  background: white !important;
  height: 40px !important;
  overflow: auto;
  border: none;
  border-radius: 5px;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
}

.inputselect {
  width: 100%;
    padding: 8px;
    text-decoration: none;
    background: white !important;
    height: 40px !important;
    overflow: auto;
    border: none;
    border-radius: 5px;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  
    resize: none;
}

.inputselect2 {
  width: 100%;
    padding: 8px;
    text-decoration: none;
    background: white !important;
    height: 40px !important;
    overflow: auto;
    border: none;
    border-radius: 5px;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  
    resize: none;
}

.textareapublicar{
  width: 100%;
  padding: 12px;
  text-decoration: none;
  background: #f0f2f5!important;
  border-radius: 5px;
  overflow: hidden;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
text-align: justify;
  text-justify: inter-word;
    resize: vertical;
}

.borde-tarjetapublicacion {
 box-shadow: 0px 3px 8px 1px rgb(169 164 164 / 68%);
          border: 1px solid #ced4da !important;
    background-clip: border-box !important;
}


.selector {
  /* definir una altura pequeña para forzar el scroll */
  height: 100px;
  overflow-y: scroll;
  width: 400px;

  /* cambiar el estilo por defecto de la barra de scroll */
  scrollbar-color: black white;
  scrollbar-width: 25px;
}

div::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
 
div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
div::-webkit-scrollbar-thumb {
  background-color: grey;
  outline: 0px solid white;
  border-radius: 30px;
}

@media (min-width: 992px){
.header-fixed .wrapper {
    padding-top: 60px!important;
}



}

@media (max-width: 425px){
  
.offcanvas {
    width: 100% !important; 
}}



legend {
  display: block;
  width: 50%!important;
  max-width: 100%;
  min-width: 50%;
  padding: 2px 0px;
 margin: 0px!important; 
margin-bottom: .2rem;
margin-left: 0px!important;
  font-size: 12px;
  line-height: inherit;
  color: black;
  font-weight: bold;
  white-space: normal;
  background-color: #bdc3c7;
  margin-top: -10px !important;
  background-color: #ffff;
  border: 1px solid #bdc3c7 !important;
}


.bad{
  position: absolute!important;
  top: 2px!important;
  margin-left: 10px ;
  height: 23px;
  background-color: #2196F3;
  color: white!important;
}

.muted > *{
  color: #B5B5C3 ;
}

.badge-danger {
  color: #ffffff;
  background-color: red;
}

.badge-success {
  color: #ffffff;
  background-color: green;
}

.acomodate{
  width: 230px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
}

.chat_submit_box {
  bottom: 12px!important;
  box-sizing: border-box;
  left: 0;
  overflow: hidden;
  padding: 2px;
  position: absolute;
  width: 100%;
  border: 1px
  solid #ccc;
  background-color: white;
}


.borde-tarjeta-login {
box-shadow: 3px 7px 15px 1px rgb(21, 19, 19);;
  background-clip: border-box !important;}

.toast-error{
  border: none!important;
  background-color: rgb(247, 108, 108)!important;
  text-align: center;
  margin: 0;
}

.tituloestilo{
  font-family: 'Billabong', sans-seri!important;
  font-size: 3.5rem!important;
}

.tituloestilo:hover{
  color:#7ac0f7
}


.desc {
  overflow: hidden;
  font-size: 1rem;
  line-height: 20px;
  color: #737373;
  text-overflow: ellipsis;
}

.novisto{
 background-color: #e7e7e7a3!important;
}

.visto{
  background-color: white!important;
 }


 .topContainer{
  display: -webkit-box;
  flex-direction: row;
  overflow: auto;
  max-width: 440px!important;
  margin-left: 5px;
  margin-bottom: 12px;
}

.wraper{
  flex-wrap: wrap;
  max-width: 120px;
  min-width: 120px;
}

.textoPerfil{
  font-size: 12px;
  text-align: left;
}


.btn-primary-vet{
  background-color: #004aad!important;
  border-color: #004aad!important;
}

.text-btn{
  font-size:15px!important;
}

.borde-botones{
box-shadow: 4px 4px 3px 0px rgba(9, 9, 9, 0.35);
background-clip: border-box !important;
}

.bg-btn-vet{
  padding:8px 8px!important;
  border-radius:5px!important;
  border: 1px solid #e4e6eb!important;
  border-color:#e4e6eb!important;
background-color: #e4e6eb!important;
color:black !important;
font-weight:bold!important
}

h5{
  margin: 0!important;
}

.bg-btn-vet-opciones {
  /* padding: 8px 8px !important; */
  border-radius: 5px !important;
  border: 1px solid #e4e6eb !important;
  border-color: #e4e6eb !important;
  background-color: #e4e6eb !important;
  color: black !important;
  font-weight: bold !important;
  height: 35px !important
}

.bg-btn-vet-login {
  padding: 8px 8px !important;
  border-radius: 5px !important;

  color: #F0F2F5 !important;
  font-weight: bold !important
}



.bg-vet-blue{
  background-color: #004aad!important;
}
.colorwhite{
  color: white!important;
}
.colorblack{
  color: black !important;
}

.bg-btn-vet-danger {
  padding: 8px 8px !important;
  border-radius: 5px !important;
  border: 1px solid #d50000 !important;
  border-color: #d50000 !important;
  background-color: #d50000 !important;
  color: white !important;
  font-weight: bold !important
}

.titulosubmenusize{
  font-size: 18px!important;
}

.bg-btn-sm {
  font-size: 9px!important;
  padding: 5px!important;
}




    .react-datalist-input__textbox {
      width: 100%;
      font-size: 0.875rem;
      line-height: 1.25rem;
      margin: 0;
      border: none !important;
      background: #ffff !important;
      height: 28px !important;
      border-radius: none !important;
    }

.botoneliminar{
      font-size: 20px;
    }

.bordecompleto {
          border: 1px solid #ced4da !important;
        }
                .bordecompletoazul {
                  border: 3px solid #243c54 !important;
                }

.bordecompletoerror {
                  border: 1.5px solid red !important;
                }
    
.bordevertical {
          border-top: 1px solid #ced4da !important;
          border-bottom: 1px solid #ced4da !important;
        }
    
        .form-control {
          border: none !important;
          ;
        }

                .bgfondo2 {
                  background-color: #f0f2f5;
                 /*  background-color: #f9F9F9; */
                }

                .modal80{
                  max-width: 80%!important;
                }

                .tabla-detalle-factura  thead  tr {
                      text-align: center;
                      background:#f5f7fc!important;
                }

                .tabla-detalle-factura thead tr th {
 
  padding: 12px!important;
}

                 .tabla-detalle-factura tbody  tr {
                      text-align: center;
                      background:#ffff!important;
                }

                .tabla-detalle-factura tbody tr td {
 
  padding: 8px!important;
}

 .emisor tbody tr td {
 
  padding: 3px!important;
}
                
.tablares{
  width: 100%;
  overflow-x: auto;
}

.btn-tabla-factura{
  width: 40px;
  height: 20px;
  border: none;
}

.borderadiusinput{
  border-radius: 5px;
}

.bordesuperiorizquierdo{
    border-top-left-radius: 20px;
}
.bordeinferiorizquierdo {
border-bottom-left-radius: 20px;
}
.bordesuperiorderecho {
  border-top-right-radius: 20px;
}
.bordeinferiorderecho {
  border-bottom-right-radius: 20px;
}
.espacioderecho{
  margin-right: 10px;
}
                
.active-descuento{
  background-color: #004aad;
  color: white;
}


.svg2 {
  width: 100% !important;
  height: auto!important;
}
.svgpreloadfactura{
  width: 150px !important;
    height: auto !important;
}

.tabalconmenu {
  position: relative;
  width: 100%;
  border-radius: inherit;
  overflow-x: inherit !important;
  overflow-y: inherit !important;
  min-height: 0;
  max-height: 300px;
  -webkit-overflow-scrolling: touch;
}

.scrollx{
  overflow-x: scroll;
}
.scrollauto{
  overflow-x: auto;
}
.scrollin {
  overflow-x: inherit;
}


/* .ddmenu{
  margin-left: -120px!important;
  position :inherit!important
} */


/*.
sc-dmRaPn
iXRfeU */

.bordederecho{
  border-right: 1px solid #ced4da !important;
}


.bordeizquierdo {
  border-left: 1px solid #ced4da !important;
}

.bordearriba{
  border-top: 1px solid #ced4da !important;
}
.bordeabajo {
  border-bottom: 1px solid #ced4da !important;
}

.bordevertical{
    border-left: none !important;
      border-right: none !important;
      border-bottom: 1px solid #ced4da !important;
        border-top: 1px solid #ced4da !important;
}

.bordehorizontal{
    border-bottom: none !important;
      border-top: none !important;
      border-left: 1px solid #ced4da !important;
        border-right: 1px solid #ced4da !important;
}



.dropoculto {
  display: none !important;
}


.textmenu{
  font-size: 15px!important;
  font-weight: 750!important;
}

.infoadicional {
  width: 100%;
  padding: 25px;
  text-decoration: none;
  background: #fdfdfd !important;
  border-radius: 5px;
  overflow: hidden;
  border: none;
  outline: none;    
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: justify;
  text-justify: inter-word;
  resize: vertical;
}

.btn-mediano{
  width: 120px!important
  ;
}
.bg-green{
  background-color: rgb(37, 164, 37);
  color: white;
}

.listadocumentos{
  height: 38px;
  width: 33.3%;
  background-color: white;
  color: black;
  font-size: 12px;
  font-weight: bold;
  border: none;
}

.listadocumentos:hover {
  background-color:#fc4718 ;
  color: white;
}

.documentoactivo {
  background-color: #243c54;
  color:white
}

.scrollx{
  overflow-x: scroll;
}

.accordion-body {
  padding:0
}
.imanav{
  height: 50px;
  width: 50px;
}


.w-70 {
  width: 70%;
}
.w-30{
  width: 30%;
}

@media (max-width: 662px) {
  .w-70 {
      width: 0%;
    }
  
    .w-30 {
      width: 100%;
      margin: 12px;
    }
}

.modal-backdrop.show {
  opacity: 0.8;
  background-color: white;
}

.bordesombreado {
  box-shadow: 1px 2px 3px 0px rgb(141 143 147);
  background-clip: border-box !important;
  /* box-shadow: 0 0 7px 0px rgb(187, 188, 191); */
}
.bordesombreado2 {
  box-shadow:1px 2px 10px -4px rgb(141 143 147);
  background-clip: border-box !important;
  /* box-shadow: 0 0 7px 0px rgb(187, 188, 191); */
}

.bordesombreado-submenu {
  box-shadow: 1px 2px 10px -4px rgb(141 143 147);
  background-clip: border-box !important;
  /* box-shadow: 0 0 7px 0px rgb(187, 188, 191); */
}


.toast-message-error {
  background: red;
  color: #fff;
  font-size: 20px;
  padding: 12px 12px;
  margin:12px;
  width: 100%;
  fill:white
}

.toast-message-error svg {
  fill: white
}


label {
  padding: 0px;
}


.titulomenutabla{

  width: 9px!important;
}

.size16{
  font-size: 16px!important;
}
.size17 {
  font-size: 17px !important;
}
.size18 {
  font-size: 18px !important;
}

button > *:hover{
  cursor: pointer;
}

.accordion-button:not(.collapsed) {
  color: white;
  background-color: #243c54;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
}
.accordion-button:focus {
  z-index: 3;
  border-color: #eff1f5;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%)
}

.viewer {
  background-color: #e4e4e4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 800px;
  overflow-y: auto;
  margin-bottom: 10px;
}

.azulmarino{
  background-color: #243c54!important;
}

.color-azulmarino {
  color: #243c54 !important;
}

.naranja{
    background-color: #fc4718 !important;
}

.color-naranja {
 color: #fc4718 !important;
}

.plomo{
  background-color: #dcd3d4!important;
}

.color-plomo{
  color: #dcd3d4 !important;
}


.color-blanco{
  color: #ffff !important;
}

.borderradiusnone {
  border-radius: 0 !important;
}

.iconmenu{
  font-size: 20px!important;

}

.hovernaranja:hover {
  background-color: #fc4718 !important;
  color: white!important;
}

.letrablanca{
  color: white !important;
}

.botoncircular{
  padding: 0px!important;
  border-radius: 45px!important;
  margin-right:6px;
  width: 30px;
  height: 30px;
  border:none;
}

.bgprimary{
  background:#0d6efd!important;
  color:white;
}
.bgsuccess{
  background:#31a24c;
  color:white;
}
.bgdanger{
    background-color: rgb(206, 32, 32);
    color:white;
}
.bgwarning{
background-color: rgb(244, 189, 117);
color:black;
}
.bginfo{
  background-color:#0dcaf0!important;
  color:white;
}

.bgactivo{
  background-color: #31a24c!important;
  color:white;
}
.bginactivo{
    background-color: #ffc107!important;
    color:black;
}



.textfloatleft{
  float: left;
}

.textfloatright {
  float: right;
}

.textalignleft {
  text-align: left;
}

.textalignright {
  text-align: right;
}

.bgfondo3{
  background-color: #e9e9e9;
}